import { createContext, useCallback, useEffect, useState } from "react";
import { MyCharacter, MyWeapon } from "../models/User";
import { createStore, dbGet, dbGetMyCharacters, dbGetMyWeapons, dbSaveMyCharacters, dbSaveMyWeapons, dbSet } from '../data/IonicStorage';

export type ToogleFilterKeys = 'myCharactersFilter' | 'myWeaponsFilter';

interface UserContextData {
  myCharacters: {
    [uuid: string]: MyCharacter
  },
  myWeapons: {
    [uuid: string]: MyWeapon
  },
  myCharactersFilter: boolean,
  myWeaponsFilter: boolean,
  updateMyCharacters: Function,
  updateMyWeapons: Function,
  toogleFilter: Function,
};

const initData: UserContextData = {
  myCharacters: {},
  myWeapons: {},
  myCharactersFilter: false,
  myWeaponsFilter: false,
  updateMyCharacters: () => { },
  updateMyWeapons: () => { },
  toogleFilter: () => { }
};

export const UserContext = createContext<UserContextData>(initData);

export const UserContextProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState(initData);

  const updateMyCharacters = useCallback((data: MyCharacter) => {
    setUserData(d => {
      d.myCharacters[data.uuid] = data;
      dbSaveMyCharacters(JSON.stringify(d.myCharacters));
      return Object.assign({}, d);
    });
  }, []);

  const updateMyWeapons = useCallback((data: MyWeapon) => {
    setUserData(d => {
      d.myWeapons[data.uuid] = data;
      dbSaveMyWeapons(JSON.stringify(d.myWeapons));
      return Object.assign({}, d);
    });
  }, []);

  const toogleFilter = useCallback((filter: ToogleFilterKeys, status: boolean) => {
    setUserData(d => {
      d[filter] = status;
      dbSet(filter, status);
      return Object.assign({}, d);
    });
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      await createStore();
      const myCharacters = await dbGetMyCharacters();
      const myWeapons = await dbGetMyWeapons();
      const myCharactersFilter = await dbGet('myCharactersFilter');
      const myWeaponsFilter = await dbGet('myWeaponsFilter');
      setUserData(d => Object.assign({}, d, { myCharacters, myWeapons, myCharactersFilter, myWeaponsFilter }));
    }

    setUserData(d => Object.assign({}, d, {
      updateMyCharacters,
      updateMyWeapons,
      toogleFilter
    }));

    loadUserData();
  }, [updateMyCharacters, updateMyWeapons, toogleFilter]);

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  )
}
