import { IonCard, IonCardContent, IonCardHeader, IonSpinner } from '@ionic/react';

import './Loader.scss'

interface loaderProps {}

const Loader: React.FC<loaderProps> = () => {

  return (
    <IonCard className='loader-card'>
      <IonCardHeader className='ion-text-center'>
        <IonSpinner color='primary' name='circular' />
      </IonCardHeader>

      <IonCardContent>
        Chargement des données en cours...
      </IonCardContent>
    </IonCard>
  );
}
export default Loader;
