import { IonAccordion, IonAccordionGroup, IonAvatar, IonCheckbox, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRow } from '@ionic/react';
import { useCallback, useContext, useMemo } from 'react';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { GenshinImpactContext } from '../data/GenshinImpactContext';
import { UserContext } from '../data/UserContext';
import { Weapon, WeaponType } from '../models/GenshinImpact';
import './MyWeapons.scss';

const MyWeapons: React.FC = () => {
  const Genshin = useContext(GenshinImpactContext);
  const UserData = useContext(UserContext);

  const accordionGroupsValue = useMemo(() => {
    let data: { [keys: string]: string | null } = {};
    Object.values(Genshin.data.weaponTypes).forEach(weaponType => {
      data[weaponType.name] = weaponType.name;
    });
    return data;
  }, [Genshin.data.weaponTypes]);

  const toggleAccordion = useCallback((weaponName: string) => {
    accordionGroupsValue[weaponName] = accordionGroupsValue[weaponName] ? null : weaponName;
  }, [accordionGroupsValue]);

  const onCheckboxChange = useCallback((checked: boolean, uuid: string) => {
    UserData.updateMyWeapons({
      'uuid': uuid,
      'isEnable': checked
    });
  }, [UserData]);

  const renderWeaponByType = useCallback((weaponTypeId: string) => {
    const weapons: Weapon[] = Object.values(Genshin.data.weapons)
      .filter(weapon => weapon.weapon_type.uuid === weaponTypeId);
    const weaponType: WeaponType = Genshin.data.weaponTypes[weaponTypeId];

    return (
      <IonAccordionGroup key={weaponTypeId} className='ion-margin-bottom' value={accordionGroupsValue[weaponType.name]}>
        <IonAccordion value={weaponType.name}>
          <IonItem slot='header' onClick={() => toggleAccordion(weaponType.name)}>
            <IonAvatar slot='start'>
              <img src={weaponType.icon} alt={weaponType.name} />
            </IonAvatar>
            <IonLabel>{weaponType.name}</IonLabel>
          </IonItem>

          <IonRow slot='content' className='ion-padding-vertical'>
            {weapons.map(weapon => (
              <IonCol key={weapon.uuid} size='12' sizeMd='6' sizeLg='3'>
                <IonItem>
                  <IonAvatar slot='start'>
                    <img src={weapon.icon} alt={weapon.name} />
                  </IonAvatar>
                  <IonLabel>{weapon.name}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    color='primary'
                    checked={UserData.myWeapons[weapon.uuid]?.isEnable}
                    onIonChange={e => onCheckboxChange(e.detail.checked, weapon.uuid)} />
                </IonItem>
              </IonCol>
            ))}
          </IonRow>
        </IonAccordion>
      </IonAccordionGroup>
    );
  }, [Genshin.data.weapons, Genshin.data.weaponTypes, UserData.myWeapons, onCheckboxChange, accordionGroupsValue, toggleAccordion]);

  return (
    <IonPage>
      <Header title='Profil' />

      <IonContent>
        <IonGrid fixed={true}>
          <IonRow className='ion-margin-bottom'>
            <IonCol>
              <h1 className='ion-text-center'>Mes Armes</h1>
            </IonCol>
          </IonRow>

          {Genshin.loading &&
           <IonRow>
              <IonCol>
                <Loader />
              </IonCol>
            </IonRow>
          }

          {!Genshin.loading &&
            <IonRow>
              <IonCol>
                {Object.values(Genshin.data.weaponTypes).map(weaponType => (
                  renderWeaponByType(weaponType.uuid)
                ))}
              </IonCol>
            </IonRow>
          }

        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyWeapons;
