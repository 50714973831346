import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, useIonPopover } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import { useCallback, useContext, useReducer } from 'react';
import Header from '../components/Header';
import { GenshinImpactContext } from '../data/GenshinImpactContext';
import './Planner.scss';
import moment from 'moment';
import DailyDomains from '../components/DailyDomains';
import { ToogleFilterKeys, UserContext } from '../data/UserContext';
import Loader from '../components/Loader';

const daysOfWeek = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche'
];

interface DayReducerAction {
  type: string,
  index?: number
}

function dayReducer(index: number, action: DayReducerAction) {
  switch (action.type) {
    case 'increment':
      return (index === 6) ? 0 : index + 1;
    case 'decrement':
      return (index === 0) ? 6 : index - 1;
    case 'set':
      if (typeof action.index !== 'number') throw new Error(`Un index de type number doit être indiqué pour l'action ${action.type} dans la function dayReducer()`);
      if (action.index < 0 || action.index > 6) throw new Error(`L'index doit être compris entre 0 et 6 pour l'action ${action.type} dans la function dayReducer()`);
      return action.index;
    default:
      throw new Error(`Action ${action.type} inconnu dans la function dayReducer()`);
  };
};

interface PopoverDaysProps {
  dayDispatch: React.Dispatch<DayReducerAction>
  daySelected: number,
  dismiss: Function
};

const PopoverDays: React.FC<PopoverDaysProps> = ({ dayDispatch, daySelected, dismiss }) => (
  <IonList>
    {daysOfWeek.map((text: string, index: number) => (
      <IonItem
        key={index}
        className={((index + 1) % 7 === daySelected ? 'selected' : undefined)}
        onClick={() => { dayDispatch({ type: 'set', index: (index + 1) % 7 }); dismiss(); }}>
        <IonLabel>{text}</IonLabel>
      </IonItem>
    ))}
  </IonList>
);

const Planner: React.FC = () => {
  const Genshin = useContext(GenshinImpactContext);
  const UserData = useContext(UserContext);

  const [day, dayDispatch] = useReducer(dayReducer, moment().day());
  const [popoverDaysPresent, popoverDaysDismiss] = useIonPopover(PopoverDays, { dayDispatch: dayDispatch, daySelected: day, dismiss: () => popoverDaysDismiss() });

  const onToogleFilter = useCallback((filter: ToogleFilterKeys, checked: boolean) => {
    UserData.toogleFilter(filter, checked);
  }, [UserData]);

  if (Genshin.loading) {
    return (
      <IonPage>
        <Header title='Planner' />

        <IonContent>
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol>
                <h1 className='ion-text-center'>Donjons Quotidiens</h1>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <Loader />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      <Header title='Planner' />

      <IonContent>
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>
              <h1 className='ion-text-center'>Donjons Quotidiens</h1>
            </IonCol>
          </IonRow>

          <IonRow className='ion-justify-content-center ion-margin-top row-day-selected'>
            <IonCol size='auto'>
              <IonButton onClick={() => dayDispatch({ type: 'decrement' })}>
                <IonIcon slot='icon-only' icon={arrowBackOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol sizeMd='4'>
              <IonButton
                onClick={(e) =>
                  popoverDaysPresent({
                    event: e.nativeEvent,
                    cssClass: 'days-popover',
                    mode: 'ios',
                    alignment: 'center',
                    size: 'cover'
                  })
                }
                expand='full'>
                {daysOfWeek[(day + 6) % 7]}
              </IonButton>
            </IonCol>
            <IonCol size='auto'>
              <IonButton onClick={() => dayDispatch({ type: 'increment' })}>
                <IonIcon slot='icon-only' icon={arrowForwardOutline}>
                </IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow class="ion-justify-content-center ion-margin-top">
            <IonCol size='12' sizeMd='6' sizeLg='4'>
              <IonItem>
                <IonLabel>Filtrer les personnages</IonLabel>
                <IonCheckbox
                  slot='start'
                  checked={UserData.myCharactersFilter}
                  onIonChange={e => { onToogleFilter('myCharactersFilter', e.detail.checked) }} />
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeLg='4'>
              <IonItem>
                <IonLabel>Filtrer les armes</IonLabel>
                <IonCheckbox
                  slot='start'
                  checked={UserData.myWeaponsFilter}
                  onIonChange={e => { onToogleFilter('myWeaponsFilter', e.detail.checked) }} />
              </IonItem>
            </IonCol>
          </IonRow>

          {Genshin.data.domains && Object.keys(Genshin.data.domains).length > 0 &&
            <IonRow className='ion-margin-top'>
              <IonCol>
                <DailyDomains daySelected={day} dayDispatch={dayDispatch} />
              </IonCol>
            </IonRow>
          }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Planner;
