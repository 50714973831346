import { Storage } from '@ionic/storage';

let storage: Storage;

export const createStore = async (name: string = "__myGenshinPlannerData") => {
  storage = new Storage({ name });
  await storage.create();
}

export const dbSet = (key: string, val: string | boolean) => {
  storage.set(key, val);
}

export const dbGet = async (key: string) => {
  return await storage.get(key);
}

export const dbRemove = async (key: string) => {
  return await storage.remove(key);
}

export const dbClear = async () => {
  return await storage.clear();
}

export const dbSaveMyCharacters = (data: string) => {
  storage.set('myCharacters', data);
}

export const dbGetMyCharacters = async () => {
  const data = await storage.get('myCharacters');
  if (data) {
    try {
      return JSON.parse(data);
    }
    catch (e) {
      return {}
    }
  }
  else {
    return {};
  }
}

export const dbSaveMyWeapons = (data: string) => {
  storage.set('myWeapons', data);
}

export const dbGetMyWeapons = async () => {
  const data = await storage.get('myWeapons');
  if (data) {
    try {
      return JSON.parse(data);
    }
    catch (e) {
      return {}
    }
  }
  else {
    return {};
  }
}
