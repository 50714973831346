import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global style */
import './theme/global.scss';

import { UserContextProvider } from './data/UserContext';
import { GenshinImpactContextProvider } from './data/GenshinImpactContext';
import Menu from './components/Menu';
import Planner from './pages/Planner';
import MyCharacters from './pages/MyCharacters';
import MyWeapons from './pages/MyWeapons';

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {
  return (
    <UserContextProvider>
      <GenshinImpactContextProvider>
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId='main' when='xl'>
              <Menu />
              <IonRouterOutlet id='main'>
                <Route exact path="/planner">
                  <Planner />
                </Route>
                <Route exact path="/my-characters">
                  <MyCharacters />
                </Route>
                <Route exact path="/my-weapons">
                  <MyWeapons />
                </Route>
                <Route exact path="/">
                  <Redirect to="/planner" />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </GenshinImpactContextProvider>
    </UserContextProvider>
  )
};

export default App;
