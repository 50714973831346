import { IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation } from 'react-router';
import './Menu.css';
import packageInfo from "../../package.json";

import { calendarOutline, earthSharp, mapOutline } from 'ionicons/icons';

interface MenuProps { };

interface Icon {
  slot: string,
  icon?: string,
  src?: string,
};

interface Page {
  title: string,
  path: string,
  target?: string,
  icons?: Icon[]
};

interface Routes {
  [keys: string]: Page[]
};

const routes: Routes = {
  planner: [
    {
      title: 'Donjons quotidiens',
      path: '/planner',
      icons: [{ slot: 'start', icon: calendarOutline }]
    }
  ],
  profile: [
    {
      title: 'Mes personnages',
      path: '/my-characters',
      icons: [{ slot: 'start', src: '/assets/icon_characters.png' }]
    },
    {
      title: 'Mes armes',
      path: '/my-weapons',
      icons: [{ slot: 'start', src: '/assets/icon_weapons.png' }]
    }
  ],
  other: [
    {
      title: 'Site Principal',
      path: 'https://www.genshin-impact.fr',
      target: '_blank',
      icons: [{ slot: 'start', icon: earthSharp }]
    },
    {
      title: 'Map',
      path: 'https://www.genshin-impact.fr/map',
      target: '_blank',
      icons: [{ slot: 'start', icon: mapOutline }]
    }
  ]
};

const Menu: React.FC<MenuProps> = () => {
  const location = useLocation();

  function renderIcons(icons?: Page["icons"]) {
    if (!icons) return null;

    let render: JSX.Element[] = [];
    icons.forEach((icon: Icon, i: number) => {
      if (!icon.icon && !icon.src) return;
      if (icon.icon) {
        render.push(<IonIcon key={i} slot={icon.slot} icon={icon.icon} />)
      }
      else {
        render.push(<IonImg key={i} slot={icon.slot} src={icon.src} />)
      }
    });

    return render;
  };

  function renderMenuItems(list: Page[]) {
    return list
      .filter(route => !!route.path)
      .map((p: Page, i: number) => (
        <IonMenuToggle key={p.title} autoHide={false} >
          {p.target &&
            <IonItem
              detail={false}
              href={p.path}
              routerDirection='none'
              className={location.pathname.startsWith(p.path) ? 'selected' : undefined}
              target={p.target}>
              <IonLabel>{p.title}</IonLabel>
              {renderIcons(p.icons)}
            </IonItem>
          }

          {!p.target &&
            <IonItem
              detail={false}
              routerLink={p.path}
              routerDirection='none'
              className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
              <IonLabel>{p.title}</IonLabel>
              {renderIcons(p.icons)}
            </IonItem>
          }
        </IonMenuToggle>
      ));
  };

  return (
    <IonMenu contentId='main' menuId='navigation'>
      <IonHeader>
        <IonToolbar>
          <IonTitle className='ion-text-center'>Genshin Impact FR</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines='none'>
          <IonListHeader>
            <IonLabel>Planner</IonLabel>
          </IonListHeader>
          {renderMenuItems(routes.planner)}
        </IonList>

        <IonList lines='none'>
          <IonListHeader>
            <IonLabel>Profil</IonLabel>
          </IonListHeader>
          {renderMenuItems(routes.profile)}
        </IonList>

        <IonList lines='none'>
          <IonListHeader>
            <IonLabel>Liens utiles</IonLabel>
          </IonListHeader>
          {renderMenuItems(routes.other)}
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonItem>Version {packageInfo.version}</IonItem>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
