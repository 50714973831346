import { IonAccordion, IonAccordionGroup, IonAvatar, IonCheckbox, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRow } from '@ionic/react';
import { useCallback, useContext, useMemo } from 'react';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { GenshinImpactContext } from '../data/GenshinImpactContext';
import { UserContext } from '../data/UserContext';
import { Character, Element } from '../models/GenshinImpact';
import './MyCharacters.scss';

const MyCharacters: React.FC = () => {
  const Genshin = useContext(GenshinImpactContext);
  const UserData = useContext(UserContext);

  const accordionGroupsValue = useMemo(() => {
    let data: { [keys: string]: string | null } = {};
    Object.values(Genshin.data.elements).forEach(element => {
      data[element.name] = element.name;
    });
    return data;
  }, [Genshin.data.elements]);

  const toggleAccordion = useCallback((elementName: string) => {
    accordionGroupsValue[elementName] = accordionGroupsValue[elementName] ? null : elementName;
  }, [accordionGroupsValue]);

  const onCheckboxChange = useCallback((checked: boolean, uuid: string) => {
    UserData.updateMyCharacters({
      'uuid': uuid,
      'isEnable': checked
    });
  }, [UserData]);

  const renderCharacterByElement = useCallback((elementId: string) => {
    const characters: Character[] = Object.values(Genshin.data.characters)
      .filter(character => character.element.uuid === elementId);
    const element: Element = Genshin.data.elements[elementId];

    return (
      <IonAccordionGroup key={elementId} className='ion-margin-bottom' value={accordionGroupsValue[element.name]}>
        <IonAccordion value={element.name}>
          <IonItem slot='header' onClick={() => toggleAccordion(element.name)}>
            <IonAvatar slot='start'>
              <img src={element.icon} alt={element.name} />
            </IonAvatar>
            <IonLabel>{element.name}</IonLabel>
          </IonItem>

          <IonRow slot='content' className='ion-padding-vertical'>
            {characters.map(character => (
              <IonCol key={character.uuid} size='12' sizeMd='6' sizeLg='3'>
                <IonItem>
                  <IonAvatar slot='start'>
                    <img src={character.avatar} alt={character.name} />
                  </IonAvatar>
                  <IonLabel>{character.name}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    color={character.element.slug}
                    checked={UserData.myCharacters[character.uuid]?.isEnable}
                    onIonChange={e => onCheckboxChange(e.detail.checked, character.uuid)} />
                </IonItem>
              </IonCol>
            ))}
          </IonRow>
        </IonAccordion>
      </IonAccordionGroup >
    );
  }, [Genshin.data.characters, Genshin.data.elements, UserData.myCharacters, onCheckboxChange, accordionGroupsValue, toggleAccordion]);

  return (
    <IonPage>
      <Header title='Profil' />

      <IonContent>
        <IonGrid fixed={true}>
          <IonRow className='ion-margin-bottom'>
            <IonCol>
              <h1 className='ion-text-center'>Mes Personnages</h1>
            </IonCol>
          </IonRow>

          {Genshin.loading &&
           <IonRow>
              <IonCol>
                <Loader />
              </IonCol>
            </IonRow>
          }

          {!Genshin.loading &&
           <IonRow>
            <IonCol>
              {Object.values(Genshin.data.elements).map(element => (
                renderCharacterByElement(element.uuid)
              ))}
            </IonCol>
          </IonRow>
          }

        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyCharacters;
