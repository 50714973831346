import { createContext, useCallback, useEffect, useState } from "react";
import { Character, dataJsonFetch, Domain, Element, Weapon, WeaponType } from "../models/GenshinImpact";
import { CONFIG } from "../config";

interface GenshinData {
  'characters': {
    [uuid: string]: Character
  },
  'weapons': {
    [uuid: string]: Weapon
  },
  'domains': {
    [uuid: string]: Domain
  },
  'elements': {
    [uuid: string]: Element
  },
  'weaponTypes': {
    [uuid: string]: WeaponType
  },
};

interface GenshinImpactContextData {
  data: GenshinData,
  loading: boolean,
  fetchData: Function
};

const initData: GenshinImpactContextData = {
  data: {
    'characters': {},
    'weapons': {},
    'domains': {},
    'elements': {},
    'weaponTypes': {},
  },
  loading: false,
  fetchData: () => { }
};

export const GenshinImpactContext = createContext<GenshinImpactContextData>(initData);

export const GenshinImpactContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(initData);

  const overrideData = useCallback((key: keyof GenshinData, newData) => {
    setData(d => {
      d.data[key] = newData
      return Object.assign({}, d)
    });
  }, [setData]);

  const fetchGenshinData = useCallback(() => {
    try {
      const headers = new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
      });
      setData(d => Object.assign({}, d, { loading: true }));
      fetch(CONFIG.gifr_host + '/sites/default/files/planner/data_v2.json', { cache: 'no-cache', headers: headers })
        .then(response => response.json())
        .then((data: dataJsonFetch) => {
          overrideData('characters', data.characters);
          overrideData('weapons', data.weapons);
          overrideData('domains', data.domains);
          overrideData('elements', data.elements);
          overrideData('weaponTypes', data.weaponTypes);
          setData(d => Object.assign({}, d, { loading: false }));
        });
    } catch (e) {
      setData(d => Object.assign({}, d, { data: initData, loading: false }));
    }
  }, [overrideData]);

  useEffect(() => {
    setData(d => Object.assign({}, d, { fetchData: fetchGenshinData }));
    fetchGenshinData();
  }, [fetchGenshinData]);

  return (
    <GenshinImpactContext.Provider value={data}>
      {children}
    </GenshinImpactContext.Provider>
  )
}
