import { openOutline } from 'ionicons/icons';
import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Lazy, Swiper as SwiperInterface } from 'swiper';
import 'swiper/css';

import { GenshinImpactContext } from '../data/GenshinImpactContext';
import { UserContext } from '../data/UserContext';
import { Character, CharacterTalent, CharacterTalentAscension, Domain, DomainLoot, Material, MaterialRequired, Weapon, WeaponAscension } from '../models/GenshinImpact';
import './DailyDomains.scss';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonRow } from '@ionic/react';

interface DailyDomainsProps {
  daySelected: number,
  dayDispatch: Function
};

interface MaterialsBy {
  [key: string]: string[] // uuid character/weapon: array of uuid materials
}

const DailyDomains: React.FC<DailyDomainsProps> = ({ daySelected, dayDispatch }) => {
  const Genshin = useContext(GenshinImpactContext);
  const UserData = useContext(UserContext);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const daySelectedToSlideIndex = useCallback((daySelected: number) => ((daySelected + 6) % 7), []);

  const slideIndexToDaySelected = useCallback((slideIndex: number) => ((slideIndex + 1) % 7), []);

  const onActiveIndexChange = (swiper: SwiperInterface) => dayDispatch({ type: 'set', index: slideIndexToDaySelected(swiper.activeIndex) });

  useLayoutEffect(() => {
    if (swiperInstance) {
      swiperInstance.slideTo(daySelectedToSlideIndex(daySelected))
    }
  }, [swiperInstance, daySelected, daySelectedToSlideIndex]);

  const dailyDomains = useMemo(
    () => Object.values(Genshin.data.domains).filter((domainData: Domain) => domainData.is_daily_domain),
    [Genshin.data.domains]
  );

  const materialsByCharacter = useMemo(() => {
    let characters: MaterialsBy = {};
    Object.values(Genshin.data.characters).forEach((characterData: Character) => {
      characters[characterData.uuid] = [];
      characterData.talents.forEach((talentData: CharacterTalent) => {
        talentData.talent_ascension.forEach((talentAscensionData: CharacterTalentAscension) => {
          talentAscensionData.materials_required.forEach((materialRequiredData: MaterialRequired) => {
            if (!characters[characterData.uuid].includes(materialRequiredData.material.uuid)) {
              characters[characterData.uuid].push(materialRequiredData.material.uuid);
            }
          });
        });
      });
    });
    return characters;
  }, [Genshin.data.characters]);

  const materialsByWeapon = useMemo(() => {
    let weapons: MaterialsBy = {};
    Object.values(Genshin.data.weapons).forEach((weaponData: Weapon) => {
      weapons[weaponData.uuid] = [];
      weaponData.ascension.forEach((ascensionData: WeaponAscension) => {
        ascensionData.materials_required.forEach((materialRequiredData: MaterialRequired) => {
          if (!weapons[weaponData.uuid].includes(materialRequiredData.material.uuid)) {
            weapons[weaponData.uuid].push(materialRequiredData.material.uuid);
          }
        });
      });
    });
    return weapons;
  }, [Genshin.data.weapons]);

  const renderMaterials = useCallback((materials: DomainLoot['materials']) => {
    const items: JSX.Element[] = [];
    Object.values(materials).forEach((material: Material) => {
      items.push(
        <div className='loot-item' key={material.uuid} title={material.name}>
          <img src={material.icon} alt={material.name} />
        </div>
      )
    });
    return items;
  }, []);

  const renderRowLoots = useCallback((loots: DomainLoot[]) => {
    const rows: JSX.Element[] = [];
    loots.forEach((domainLoot: DomainLoot, index: number) => {
      rows.push(
        <div className='loots-row' key={index}>
          {renderMaterials(domainLoot.materials)}
        </div>
      );
    });
    return rows
  }, [renderMaterials]);

  const renderCharacters = useCallback((materialsIds: string[]) => {
    const render: JSX.Element[] = [];

    const charactersIds: string[] = [];
    materialsIds.forEach(materialId => {
      for (let characterId in materialsByCharacter) {
        // If characters filter enable and current character isn't enable, skip it.
        if (UserData.myCharactersFilter && !UserData.myCharacters[characterId]?.isEnable) continue;
        if (charactersIds.includes(characterId)) continue;
        if (materialsByCharacter[characterId].includes(materialId)) {
          charactersIds.push(characterId);
        }
      }
    });

    charactersIds.forEach(characterId => {
      const characterData: Character = Genshin.data.characters[characterId];
      if (characterData.url) {
        render.push(
          <IonItem className='character' key={characterData.uuid} title={characterData.name} href={characterData.url} target='_blank'>
            <img className='character-avatar swiper-lazy' data-src={characterData.avatar} alt={characterData.name} />
            <img className='character-element' src={characterData.element.icon} alt={characterData.element.name} />
            <IonIcon className='character-icon-web-page' icon={openOutline}></IonIcon>
          </IonItem>
        );
      }
      else {
        render.push(
          <div className='character' key={characterData.uuid} title={characterData.name}>
            <img className='character-avatar swiper-lazy' data-src={characterData.avatar} alt={characterData.name} />
            <img className='character-element' src={characterData.element.icon} alt={characterData.element.name} />
          </div>
        );
      }
    });

    if (render.length > 0) {
      render.unshift(<div className='characters-title' key='characters-title'>Personnages</div>);
    }

    return render;
  }, [Genshin.data.characters, materialsByCharacter, UserData.myCharacters, UserData.myCharactersFilter]);

  const renderWeapons = useCallback((materialsIds: string[]) => {
    const render: JSX.Element[] = [];

    const weaponsIds: string[] = [];
    materialsIds.forEach(materialId => {
      for (let weaponId in materialsByWeapon) {
        // If weapons filter enable and current weapon isn't enable, skip it.
        if (UserData.myWeaponsFilter && !UserData.myWeapons[weaponId]?.isEnable) continue;
        if (weaponsIds.includes(weaponId)) continue;
        if (materialsByWeapon[weaponId].includes(materialId)) {
          weaponsIds.push(weaponId);
        }
      }
    });

    weaponsIds.forEach(weaponId => {
      const weaponData: Weapon = Genshin.data.weapons[weaponId];
      render.push(
        <div className='weapon' key={weaponData.uuid} title={weaponData.name}>
          <img className='weapon-icon swiper-lazy' data-src={weaponData.icon} alt={weaponData.name} />
          <div className='weapon-name'>{weaponData.name}</div>
        </div>
      )
    });

    if (render.length > 0) {
      render.unshift(<div className='weapons-title' key='weapons-title'>Armes</div>);
    }

    return render;
  }, [Genshin.data.weapons, materialsByWeapon, UserData.myWeapons, UserData.myWeaponsFilter]);

  const renderDailyDomains = useCallback((day: number) => {
    const render: JSX.Element[] = [];

    if (dailyDomains.length < 1) return render;

    dailyDomains.forEach((domainData: Domain, index: number) => {
      // Get domainLoot available this day.
      const lootsOfDay = domainData.loots.filter((domainLoot: DomainLoot) => domainLoot.available_days.includes(day.toString()));

      // Create simple array of materials uuids available this day.
      // Useful to filter characters who need this domain this day.
      let lootsIdsOfDay: string[] = [];
      lootsOfDay.forEach((domainLoot: DomainLoot) => {
        lootsIdsOfDay = lootsIdsOfDay.concat(Object.keys(domainLoot.materials));
      });

      if (lootsOfDay.length > 0) {
        render.push(
          <IonCol key={'d-' + day + '-' + index} size='12' sizeMd='6' sizeLg='4'>
            <IonCard>
              <img src={domainData.image} alt={domainData.name} />
              <IonCardHeader>
                <IonCardTitle>{domainData.name}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <div className="loots">{renderRowLoots(lootsOfDay)}</div>
                <div className="characters">{renderCharacters(lootsIdsOfDay)}</div>
                <div className="weapons">{renderWeapons(lootsIdsOfDay)}</div>
              </IonCardContent>
            </IonCard>
          </IonCol>
        );
      }
    });

    return render;
  }, [dailyDomains, renderRowLoots, renderCharacters, renderWeapons]);

  const renderSlides = useCallback(() => {
    const slides: JSX.Element[] = [];
    [1, 2, 3, 4, 5, 6, 0].forEach(day => {
      slides.push(
        <SwiperSlide key={day}>
          <IonGrid>
            <IonRow>{renderDailyDomains(day)}</IonRow>
          </IonGrid>
        </SwiperSlide>
      );
    });
    return slides;
  }, [renderDailyDomains]);

  return (
    <Swiper
      onSwiper={(swiper) => setSwiperInstance(swiper)}
      autoHeight={true}
      loop={false}
      initialSlide={daySelectedToSlideIndex(daySelected)}
      onActiveIndexChange={onActiveIndexChange}
      observer={true}
      observeParents={true}
      onObserverUpdate={(swiper => swiper.lazy.load())}
      modules={[Lazy]}
    >
      {renderSlides()}
    </Swiper>
  );
};

export default DailyDomains;
